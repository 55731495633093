import React from 'react';
import { withRouter } from 'react-router';
import { auth, db, firestore, analytics } from '../../firebase';
import { NavLink } from 'react-router-dom';

/**
 * Componente `Header`
 */
function Header(props) {
  const { user }          = props;
  const { custom_user }   = props;
  const { config_global } = props;
  const { show_login }    = props;
  const { show_logout }   = props;

  if(user === false || custom_user === false || config_global === false) return (<h1>Cargando...</h1>);
  
  return(
    <header className="header">
      <span className="logo_squadra"></span>
      <p className="welcome">Bienvenid@ { (custom_user?.name.length >= 23)?custom_user?.name.substr(0, 23) + '...':custom_user?.name }</p>
      {/* <input type="button" className="btn" value="Cerrar Sesión" onClick={e => { props.history.push('/logout') }}/> */}
    </header>
  )
}

export default withRouter(Header);
