import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import { auth, db, firestore, analytics } from '../../firebase';
import ImageGallery from 'react-image-gallery';

/**
 * Componente `Photos`
 */
function Photos(props) {
  const images = [{
    original: '/photos/2019/01_image.jpeg',
    thumbnail: '/photos/2019/01_thumbs.jpeg',
  },
  {
    original: '/photos/2019/02_image.jpeg',
    thumbnail: '/photos/2019/02_thumbs.jpeg',
  },
  {
    original: '/photos/2019/03_image.jpeg',
    thumbnail: '/photos/2019/03_thumbs.jpeg',
  },
  {
    original: '/photos/2019/04_image.jpeg',
    thumbnail: '/photos/2019/04_thumbs.jpeg',
  },
  {
    original: '/photos/2019/05_image.jpeg',
    thumbnail: '/photos/2019/05_thumbs.jpeg',
  },
  {
    original: '/photos/2019/06_image.jpeg',
    thumbnail: '/photos/2019/06_thumbs.jpeg',
  },
  {
    original: '/photos/2019/07_image.jpeg',
    thumbnail: '/photos/2019/07_thumbs.jpeg',
  },
  {
    original: '/photos/2019/08_image.jpeg',
    thumbnail: '/photos/2019/08_thumbs.jpeg',
  },
  {
    original: '/photos/2019/09_image.jpeg',
    thumbnail: '/photos/2019/09_thumbs.jpeg',
  },
  {
    original: '/photos/2019/10_image.jpeg',
    thumbnail: '/photos/2019/10_thumbs.jpeg',
  },
  {
    original: '/photos/2019/11_image.jpeg',
    thumbnail: '/photos/2019/11_thumbs.jpeg',
  },
  {
    original: '/photos/2019/12_image.jpeg',
    thumbnail: '/photos/2019/12_thumbs.jpeg',
  },
  {
    original: '/photos/2019/13_image.jpeg',
    thumbnail: '/photos/2019/13_thumbs.jpeg',
  },
  {
    original: '/photos/2019/14_image.jpeg',
    thumbnail: '/photos/2019/14_thumbs.jpeg',
  }
];

  return(
    <Fragment>
      <div className="page">
        <ImageGallery items={images} />
      </div>
    </Fragment>
  )
}

export default withRouter(Photos);
