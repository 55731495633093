import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { auth, db, firestore, analytics } from '../../firebase';
import Header from '../../components/2020/Header';

function Bases(props) {
  const { user } = props;
  const { custom_user } = props;
  const { config_global } = props;

  const [loaded, setLoaded] = useState(false);

  /**
   *
   */
  const savePDF = () => {
    if(custom_user.code) window.open(`https://squadra.reale.cl/bases/${custom_user.code}.pdf`);

    return true;
  }

  return(
    <div className="page dark-10">
      <Header user={user} custom_user={custom_user} config_global={config_global} />
      <h1>Bases Legales</h1>
      <p>   En Santiago de Chile, a 04 de julio de 2019, Reale Chile Seguros Generales S.A. en adelante también LA COMPAÑÍA, sociedad del giro de su denominación social, Rol Único tributario 76.743.492-8, representada por don Oscar Huerta, cédula nacional de identidad N°23.888.108-8 y por don Eduardo Couyoumdjian, cédula de identidad N° 7.106.347-k, todos domiciliados ubicado en Los Militares N° 5890, piso 12, comuna Las Condes, Ciudad de Santiago; establecen las siguientes Bases de Concurso “Convención Reale 2019-2020” en adelante también “Las Bases”</p>
      <br/>
      <p>
        <button className="btn" onClick={(e) => {
          // init();
          savePDF();
        } }>Descargar</button>
      </p>
    </div>
  )
}

export default withRouter(Bases);