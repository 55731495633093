import React from 'react';
import { withRouter } from 'react-router';
import { auth, db, firestore, analytics } from '../../firebase';
import Header from '../../components/2020/Header';
import Trivia from '../../components/Trivia';

function Game(props) {
  const { user } = props;
  const { custom_user } = props;
  const { config_global } = props;

  return(
    <div className="page dark-10">
      <Header user={user} custom_user={custom_user} config_global={config_global} />
      <h1>Trivia</h1>
      <div className="game">
        <Trivia user={user} custom_user={custom_user} config_global={config_global} />
      </div>

      {/* <div style="background-color: #ffc800; color: #252100; padding: 10px;">Fe de erratas: Por error fue enviado un correo electrónico anteriormente con un formato incorrecto, donde se indicaba ingresar a un portal, procedimiento que está incorrecto, por lo que les pedimos omitirlo. El procedimiento correcto es responder con el adjunto en este mismo correo electrónico, no ingresar a ningún sistema.</div> */}
    </div>
  )
}

export default withRouter(Game);