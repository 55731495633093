import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyDd94ITVLNMWvPwVWF2IWgK1MaLIm4NTGw",
  authDomain: "convencion-2020.firebaseapp.com",
  databaseURL: "https://convencion-2020.firebaseio.com",
  projectId: "convencion-2020",
  storageBucket: "convencion-2020.appspot.com",
  messagingSenderId: "429070070546",
  appId: "1:429070070546:web:992472f1be525671c9f9cb",
  measurementId: "G-KRXP7ZGC9Y"
};

firebase.initializeApp(firebaseConfig);

const database  = firebase.database();
const firestore = firebase.firestore();
const auth      = firebase.auth();
const analytics = firebase.analytics();

export {database, firestore, auth, analytics, firebase};
