import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import { auth, db, firestore, analytics } from '../../firebase';
import { Link } from 'react-router-dom';

/**
 * Componente `Home`
 */
function Home(props) {
  
  return(
    <Fragment>
      <div className="page">
        <div className="col col-60">
          <div className="content vcenter">
            <h1>Convención 2019</h1>
            <p className="text">¡Todos a bordo!, entre el 15 y el 27 de mayo del 2019 nos sumergimos a esta aventura junto a 18 socios estratégicos y sus acompañantes con quienes visitamos y disfrutamos de unas tapas en Madrid para luego embarcarnos en un crucero y recorrer Copenhague,  Estocolmo, Tallin, San Petersburgo, Helsinki y Segovia.</p>
            <br/>
            <p className="text">Tuvimos la oportunidad de asistir a la típica ceremonia del cochinillo en Cándido, entre muchas otras actividades que nos hicieron vivir una experiencia inolvidable.</p>
          </div>
        </div>
        <div className="col col-40 photo">
          <div className="content vcenter">
            <img src="/img/2019.png" alt=""/>
            <Link to="/2019/photos" className="btn">Conoce las Fotos</Link>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default withRouter(Home);
