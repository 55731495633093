import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import { auth, db, firestore, analytics } from '../firebase';

/**
 * Componente `Home`
 */
function Home(props) {
  const { user } = props;
  const { custom_user } = props;
  const { config_global } = props;

  const goTo2018 = () => { props.history.push('/2018') };
  const goTo2019 = () => { props.history.push('/2019') };
  const goTo2020 = () => { props.history.push('/2020') };
  const goTo2021 = () => { props.history.push('/2021') };

  if(user === false || custom_user === false || config_global === false) return (<h1>Cargando...</h1>);

  return(
    <Fragment>
      <div className="page">
        <div className="content">
          <div className="welcome">
            <h1>
              <strong>Bienvenido(a) a</strong><br/>Squadra Corredor Reale
            </h1>
            <br/>
            <p>¡Ya eres parte del grupo de corredores que forma esta Squadra Reale!. Cada uno de ustedes es una parte esencial de nuestra historia y pieza fundamental para nuestro equipo.</p>
            <br/>
            <p>Los invitamos a descubrir las aventuras en las que nos hemos embarcado junto a nuestros socios estratégicos más cercanos y a descubrir cuál será nuestro destino de la “Convención 2021”.</p>
            <br/>
            <p>Ya eres parte de Squadra Corredor Reale.</p>
            <br/>
            <p>¡Bienvenido!</p>
            <br/>
            <br/>
            <input type="button" className="btn" value="Cerrar Sesión" onClick={e => { props.history.push('/logout') }}/>

          </div>

          <div className="welcome-shortcuts">
            <h1><strong>Convenciones</strong> Squadra</h1>
            <ul className="shortcutsList">
              {(custom_user && custom_user.show2018)?<li className="shortcutsList-item shortcutsList-item_2018" onClick={ goTo2018 }>
                <div className="body"></div>
                <div className="title">
                  <div className="icon sprite_home"></div>
                  <div className="label">2018</div>
                </div>
              </li>:null}
              {(custom_user && custom_user.show2019)?<li className="shortcutsList-item shortcutsList-item_2019" onClick={ goTo2019 }>
                <div className="body"></div>
                <div className="title">
                  <div className="icon sprite_home"></div>
                  <div className="label">2019</div>
                </div>
              </li>:null}
              {(custom_user && custom_user.show2020)?<li className="shortcutsList-item shortcutsList-item_2020" onClick={ goTo2020 }>
                <div className="body"></div>
                <div className="title">
                  <div className="icon sprite_home"></div>
                  <div className="label">2020</div>
                </div>
              </li>:null}
              {(custom_user && custom_user.show2021)?<li className="shortcutsList-item shortcutsList-item_2021" onClick={ goTo2021 }>
                <div className="body"></div>
                <div className="title">
                  <div className="icon sprite_home"></div>
                  <div className="label">2021</div>
                </div>
              </li>:null}
              {(!custom_user || (!custom_user.show2018 && !custom_user.show2019 && !custom_user.show2020 && !custom_user.show2021))?<p>Actualmente no tenemos onvenciones activas</p>:null}
            </ul>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default withRouter(Home);
