import React, { Fragment, useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import {auth, db} from '../firebase';

/**
 * Componente `Logout`
 */
function Logout(props) {
  const {user} = props;

  // Escucha Inicial
  useEffect(() => {
    auth.signOut();
    props.history.push('/');
  }, []);

  // const cerrarSession = async () => {
  // }

  // Loading
  if(user === false) return (<div className="loading">Cargando...</div>);

  // cerrarSession()
  
  // JSX
  return(
    <div>
      
    </div>
  )
}

export default withRouter(Logout);