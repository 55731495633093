import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import { auth, db, firestore, analytics } from '../../firebase';
import { Link } from 'react-router-dom';

/**
 * Componente `Home`
 */
function Home(props) {
  
  return(
    <Fragment>
      <div className="page">
        <div className="col col-60">
          <div className="content vcenter">
            <h1>Convención 2018</h1>
            <p className="text">En esta primera aventura nos acompañaron 16 socios estratégicos y visitamos lugares emblemáticos de Madrid, así como también nuestra casa matriz donde pudimos reunirnos con los principales directivos de la compañía. Además, tuvimos la oportunidad de recorrer Barcelona, Milán, Verona y Venecia donde enseñamos también - a cada uno de los asistentes - la casa matriz de Turín donde todo comenzó.</p>
            <br/>
            <p className="text">¡Nos quedamos con los mejores recuerdos de cada uno de los participantes!</p>
          </div>
        </div>
        <div className="col col-40 photo">
          <div className="content vcenter">
            <img src="/img/2018.png" alt=""/>
            <Link to="/2018/photos" className="btn">Conoce las Fotos</Link>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default withRouter(Home);
