import {firebase}  from 'firebase';
import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import { PopupboxManager } from 'react-popupbox';

export const showAuthError = (e, titleOverride) => {
  if(!e) return false;

  let message = '';

  const config = {
    titleBar: {
      text: (titleOverride)?titleOverride:'',
      enable: true
    },
    overlayOpacity: 0.75,

    // Overide
    onOpen    : () => {},
    onComplete: () => {},
    onClosed  : () => {}
  }

  if(e.hasOwnProperty('code')) {
    switch(e.code) {
      case 'auth/operation-not-allowed':
       message = 'La operación de autenticación no es permitida';
      break;

      case 'auth/user-not-found':
       message = 'El usuario no existe';
      break;

      case 'auth/email-already-in-use':
       message = 'El correo electrónico indicado ya se ecuentra registrado';
      break;

      case 'auth/account-exists-with-different-credential':
       message = 'El correo electrónico indicado ya se ecuentra registrado';
      break;

      case 'auth/wrong-password':
       message = 'El correo electrónico ingresado no es válido';
      break;

      default:
        message = 'Error en la operación';
    }

  const content = <div className="body center">
    <Fragment>
      <div>{message}.</div>
      <br/>
      <br/>
      <button className="btn btn-normal" onClick={(e) => {
        PopupboxManager.close();
      }}>Cerrar Mensaje</button>
    </Fragment>
  </div>

    PopupboxManager.open({content, config});
  }
}

export const showMessage = (message, title) => {
  if(!message) return false;

  const config = {
    titleBar: {
      text: title,
      enable: true
    },
    overlayOpacity: 0.75,

    // Overide
    onOpen: () => {},
    onComplete: () => {},
    onClosed: () => {}
  }

  const content = <div className="body center">
    <Fragment>
      <div>{message}</div>
      <br/>
      <button className="btn btn-normal" onClick={(e) => {
        PopupboxManager.close();
      }}>Cerrar Mensaje</button>
    </Fragment>
  </div>

  PopupboxManager.open({content, config});
}

export const isRUT = (rut) => {
  let clearValue = rut.replace(/[^\dkK]/g, '').toUpperCase(),
      arr_rut    = [],
       T         = null;

  if (/^[0-9]{6,9}\-?[0-9kK]{1}$/.test(clearValue)) {
    arr_rut = [clearValue.substr(0, clearValue.length - 1), clearValue.substr(clearValue.length - 1)];
    T       = arr_rut[0];

    let M = 0,
        S = 1;

    for (; T; T = Math.floor(T / 10))
      S = (S + T % 10 * (9 - M++ % 6)) % 11;
    if (arr_rut[1] == (S ? S - 1 : 'K')) {
      return true;
    }

    debugger;

    return false;
  }

  return false;
}

export const formatRUT = (rut, type = 2) => {
  let clearValue = rut.replace(/[^\dkK]/g, ''),
  result;
  
  if (type === 1) {
    result = clearValue.slice(-4, -1) + '-' + clearValue.substr(clearValue.length - 1);
    for (var i = 4; i < clearValue.length; i += 3) result = clearValue.slice(-3 - i, -i) + '.' + result;
  } else if (type === 2) {
    result = clearValue.slice(0, -1) + '-' + clearValue.substr(clearValue.length - 1);
  } else {
    result = clearValue;
  }
  
  return result.toUpperCase();
}

/**
 * Tipos de formato
 * - 1 ->  00.000.000
 * - 2 ->  00.000.000,00
 */
export const numberFormat = (value, type) => {
  value = this.numberClean(value);

  if (!type){
    type = 1;
  }

  if (type === 2) {
    value = value.toFixed(2);
  }

  let decimal = value.toString().replace(/(\d*\.?)/, '');
  if (decimal) decimal = ',' + decimal.replace(/\D*/, '');

  value = ("" + parseInt(value)).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, function ($1) {
    return $1 + "."
  });

  return value + decimal;
}

export default {};