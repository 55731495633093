import React from 'react';
import { withRouter } from 'react-router';
import { auth, db, firestore, analytics } from '../../firebase';
import { NavLink } from 'react-router-dom';

/**
 * Componente `Header`
 */
function Header(props) {
  const { user } = props;
  const { custom_user } = props;
  const { config_global } = props;

  if(user === false || custom_user === false || config_global === false) return (<h1>Cargando...</h1>);
  
  return(
    <header className="header">
      {/* <NavLink to="user"> */}
        <p>Bienvenid@ { (custom_user?.name.length >= 23)?custom_user?.name.substr(0, 23) + '...':custom_user?.name }</p>
        {/* <p>{ JSON.stringify(custom_user) }</p> */}
        {/* <p>Tus puntos: { custom_user.total } <span className="see_detail">(ver detalle)</span></p> */}
      {/* </NavLink> */}
    </header>
  )
}

export default withRouter(Header);
