import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import { auth, db, firestore, analytics } from '../../firebase';
import MenuItem from './MenuItem';

/**
 * Componente `Menu`
 */
function Menu(props) {
  const { click_callback } = props;
  const { user } = props;
  const { custom_user } = props;
  const { config_global } = props;

  console.log(custom_user);

  return(
  
    <Fragment>
      <ul className="menu_list">
        <MenuItem label="Inicio"     icon="ico_home"       path="/home"            click_callback={(click_callback)?click_callback:null} />
        <MenuItem label="Principal"  path="/2020/home"       click_callback={(click_callback)?click_callback:null} />
        <MenuItem label="Convención" path="/2020/convention" click_callback={(click_callback)?click_callback:null} />
        <MenuItem label="Ciudades"   path="/2020/cities"     click_callback={(click_callback)?click_callback:null} />
        {
          (custom_user && custom_user.hasOwnProperty('isgr'))?
            (custom_user.isgr === true)?
              <MenuItem label="Mis Corredores" path="/2020/gr" click_callback={(click_callback)?click_callback:null} />
              :
              (custom_user.isgr === false)?
                <MenuItem label="Mis Puntos" path="/2020/user" click_callback={(click_callback)?click_callback:null} />
              :
              null
          :
          null
        }
        {/* <MenuItem label="Bases"      path="/2020/bases"      click_callback={(click_callback)?click_callback:null} /> */}
        {
          (config_global.c2020?.show_trivia)?<MenuItem label="Trivia"      path="/2020/game"      click_callback={(click_callback)?click_callback:null} classStyle="text_color_loop" />:null
        }
      </ul>
    </Fragment>
  )
}

export default withRouter(Menu);