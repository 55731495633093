import React from 'react';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';

/**
 * Componente `Header`
 */
function Header(props) {
  return(
    <header className="header">
      <NavLink to="/">
        <div className="logo sprite_home"></div>
        <span className="logo_squadra sprite"></span>
      </NavLink>
    </header>
  )
}

export default withRouter(Header);
