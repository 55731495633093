import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import { auth, db, firestore, analytics } from '../../firebase';
import MenuItem from './MenuItem';

/**
 * Componente `Menu`
 */
function Menu(props) {
  const { click_callback } = props;
  const { user } = props;
  const { custom_user } = props;
  const { config_global } = props;

  const closeSession = () => {
    props.history.push('/');
  }

  return(
  
    <Fragment>
      <ul className="menu_list">
        <MenuItem label="Inicio"     icon="ico_home"       path="/home"            click_callback={(click_callback)?click_callback:null} />
        <MenuItem label="Principal"  icon="ico_principal"  path="/2021/home"            click_callback={(click_callback)?click_callback:null} />
        <MenuItem label="Convención" icon="ico_convention" path="/2021/convention" click_callback={(click_callback)?click_callback:null} />
        <MenuItem label="Ciudades"   icon="ico_cities"     path="/2021/cities"     click_callback={(click_callback)?click_callback:null} />
        {
          (custom_user && custom_user.hasOwnProperty('isgr'))?
            (custom_user.isgr === true)?
              <MenuItem label="Mis Corredores" icon="ico_points" path="/2021/gr" click_callback={(click_callback)?click_callback:null} />
              :
              (custom_user.isgr === false)?<MenuItem label="Mis Puntos" icon="ico_points" path="/2021/user" click_callback={(click_callback)?click_callback:null} />
            :
            null
          :
          null
        }
        <MenuItem label="Bases"         icon="ico_bases"   path="/2021/bases"      click_callback={(click_callback)?click_callback:null} />
        {/* <MenuItem label="Cerrar Sesión" icon="ico_bases"   path="/logout" classStyleItem="btn_logout" /> */}
 
      </ul>
    </Fragment>
  )
}

export default withRouter(Menu);