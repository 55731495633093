import React, { Fragment, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { auth, db, firestore, analytics } from '../../firebase';

/**
 * Componente `Home`
 */
function Home(props) {
  const { user } = props;
  const { custom_user } = props;
  const { config_global } = props;
  
  const [diff, setDiff] = useState((new Date(2021, 1, 31, 0, 0).getTime() - new Date().getTime()));
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);

  useEffect(() => {
    // setDiff(new Date().getTime() - new Date(2021, 0, 31).getTime());
    countdown()
  }, []);

  const countdown = () => {
    setInterval(() => {
      setDays(Math.floor(diff / (1000 * 60 * 60 * 24)));
      setHours(Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
    }, 1000)
  }

  const goToTrivia = () => {
    props.history.push('/2020/game');
  }

  if(!auth.getUid()) return 'No estás autenticado.'

  return(
    <Fragment>
      <div className="page">
        <div className="welcome">
          <div className="welcome-top sprite"></div>
          <div className="welcome_separator-top sprite"></div>
          <h1 className="title">Un Verdadero Viaje en el Tiempo</h1>
          <div className="welcome_separator-bottom sprite"></div>
          <h2 className="subtitle">Capitales Imperiales</h2>
          <div className="welcome_logo sprite"></div>
          <h2 className="subtitle">Convención 2020</h2>
          <div className="welcome-bottom sprite"></div>
          <br/>
          {/* <input type="button" className="btn" value="Cerrar Sesión" onClick={e => { props.history.push('/logout') }}/> */}

        </div>

        {
          (config_global.home2020.show_counter)?<div className="countdown">
            <h3>Prepárate, sólo faltan...</h3>
            <div className="days">{days} días, {hours} horas</div>
            <p>* Viaje por confirmar, según situación actual.</p>
          </div>:null
        }

        {
          (config_global.c2020?.show_trivia)?<div className="logo_trivia" onClick={goToTrivia}></div>:null
        }
      </div>
    </Fragment>
  )
}

export default withRouter(Home);
