import React, { Fragment, useState } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import InputField from '../../../components/InputField'
import { auth, db, firestore, analytics } from '../../../firebase';
import { PopupboxManager } from 'react-popupbox';
import {showAuthError, showMessage, isRUT, formatRUT} from '../../../Utils';

const Login = (props) => {
  
  const { user }     = props;
  const { custom_user } = props;
  const { config_global } = props;
  
  const [email, setEmail]       = useState('');
  const [password, setPassword] = useState('');
  
  const formSubmit = async (e) => {
    e.preventDefault();

    try {
      await auth.signInWithEmailAndPassword(email, password.toUpperCase());
      props.history.push('/home');
      PopupboxManager.close();
    } catch(e) {
      showAuthError(e);
    }
  }

  return (
    <Fragment>
      <div className="page">
      <h1>Inicio de Sesión</h1>
      <form onSubmit={ formSubmit } className="login">
        <InputField label="Correo Electrónico" type="email" name="email" value={ email } change_callback={[setEmail]} />
        <InputField label="Contraseña" type="password" name="password" value={ password } change_callback={[setPassword]} />
        <input type="submit" value="Iniciar" className="btn" />
      </form>
      </div>
    </Fragment>
  );
}

export default withRouter(Login);