import React, { Fragment, useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { auth, db, firestore, analytics } from '../../firebase';

import Header from '../../components/2020/Header';

/**
 * Componente `User`
 */
function User(props) {
  const {user = false}          = props;
  const {custom_user = false}   = props;
  const {config_global} = props;
  const [items, setItems] = useState(false);

  let n = 0;

  // useEffect(() => {
  //   firestore.collection('info').where('uid', '==', user.uid).get()
  //   .then((d) => {
  //     if(d.docs.length && d.docs[0].exists) {
  //       setItems(d.docs.map(d=>{
  //         return d.data();
  //       }));
  
  //       console.log('Se cargó la información del usuario.', items);
  
  //     } else {
  //       console.error('El usuario no existe.');
  //     }
  //   })
  // }, [user]);


  if(user === false || config_global === false || items == false) return (<h1>Cargando...</h1>);
  
  return(
    <Fragment>
      {
        (items.length)?
        <div className="page">
          <Header user={user} custom_user={custom_user} config_global={config_global} />
          <h1>Información de Usuario</h1>
          <p className="text">¡Comienza la cuenta regresiva!</p>
          <br/>
          <p className="text">Te invitamos a descubrir cuán cerca estás de la meta para acompañarnos en esta nueva aventura. No olvides que se extendió el periodo de evaluación hasta el 31 de julio. </p>
          <div className="table_container">
            <table className="rn_Grid">
              <tbody>
                <tr key={'item_' + n++}>
                  <th>GR</th>
                  <td>{items[0].gr}</td>
                </tr>
                <tr key={'item_' + n++}>
                  <th>Meta UF</th>
                  <td>{items[0].budget}</td>
                </tr>
                { (items[0].total)?<tr key={'item_' + n++}>
                  <th>Llevas UF</th>
                  <td>{items[0].total}</td>
                </tr>:null }
                <tr key={'item_' + n++}>
                  <th>% RR.VV.</th>
                  <td>{items[0].rrvv}</td>
                </tr>
                <tr key={'item_' + n++}>
                  <th>% Auto</th>
                  <td>{items[0].auto}</td>
                </tr>
                { (items[0].left)?<tr key={'item_' + n++}>
                  <th>Te Faltan UF</th>
                  <td className="text_color_loop">{items[0].left}</td>
                </tr>:null }
                { (items[0].left_rrvv)?<tr key={'item_' + n++}>
                  <th>Te Faltan RRVV UF</th>
                  <td className="text_color_loop">{items[0].left_rrvv}</td>
                </tr>:null }
              </tbody>
            </table>
          </div>
          
          <div className="contact">
          La información contenida en la tabla podria estar sujeta a cambios. Si tienes dudas, escríbenos a fidelizacion@reale.cl
          </div>
        </div>
      :null
    }
    </Fragment>
  )
}

export default withRouter(User);