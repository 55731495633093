import React, { useState, useEffect, Fragment } from 'react';
// import { withRouter } from 'react-router';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { auth, db, firestore, analytics } from './firebase';
 
// Componentes
// import Header from './components/Header';
import Nav     from './components/Nav';
import Nav2020 from './components/2020/Nav';
import Nav2021 from './components/2021/Nav';

// Páginas
// import store      from './store';

// Páginas Pantalla Principal
import Home   from './pages/Home';
import Logout from './pages/Logout';
import Header from './components/Header';
import Header2021 from './components/2021/Header';

// Páginas convención 2018
import Home2018   from './pages/2018/Home';
import Photos2018 from './pages/2018/Photos';

// Páginas convención 2019
import Home2019   from './pages/2019/Home';
import Photos2019 from './pages/2019/Photos';

// Páginas convención 2020
import Home2020       from './pages/2020/Home';
import Login2020      from './pages/2020/auth/Login';
import Register2020   from './pages/2020/auth/Register';
import Convention2020 from './pages/2020/Convention';
import Cities2020     from './pages/2020/Cities';
import City2020       from './pages/2020/City';
import Game2020      from './pages/2020/Game';
import Bases2020      from './pages/2020/Bases';
import User2020       from './pages/2020/User';
import GR2020         from './pages/2020/GR';

// Páginas convención 2021
import Home2021       from './pages/2021/Home';
import Convention2021 from './pages/2021/Convention';
import Cities2021     from './pages/2021/Cities';
import City2021       from './pages/2021/City';
import Bases2021      from './pages/2021/Bases';
import User2021       from './pages/2021/User';
import GR2021         from './pages/2021/GR';

/**
 * SFC
 */
function App() {
  const [user, setUser] = useState(false);
  const [custom_user, setCustomUser] = useState(false);
  const [config_global, setConfigGlobal] = useState(false);
  const [activeMenu, setActiveMenu] = useState(false);
  const [offline, setOffline] = useState(false);
  const [topClass, setTopClass] = useState('');

  // const location = useLocation();
  // const match = useRouteMatch();
  // debugger;

  useEffect(() => {
    auth.onAuthStateChanged((d) => {
      if(d) {
        setUser(d);
        getCustomUser(d);
        // setUser(null);
      } else {
        setUser(null);
      }
    })


    getConfig();
    
  }, []);

  const getCustomUser = async (user_param) => {
    if(!user_param) return false;
    // const custom_user_response = await firestore.collection('users_info').where('uid','==',user_param.uid).get();
    await firestore.collection('users_info').where('uid','==',user_param.uid).onSnapshot((response) => {
      response.docs.map(d => {
        if(d.exists) {
          setCustomUser(d.data());
        }
      })
    });
    
  }

  const getConfig = async () => {
    firestore.collection('config_global').onSnapshot((config_response) => {
      let obj_config = {};
  
      if(!config_response.empty) {
        obj_config = {};
        config_response.docs.map((d, i) => {
          return obj_config[d.id] = d.data();
        });
  
        setConfigGlobal(obj_config);
        // setConfigGlobal(null);
      } else {
        setConfigGlobal(null);
      }
    });
  }

  const toggleMenu = (active = null) => {
    console.log('> toggleMenu', active);
    
    const offCanvas = document.querySelector('.offCanvas');
    if(active === null) {
      active = (offCanvas.className.indexOf('active') !== -1)?true:false;
    }

    if(active) {
      offCanvas.setAttribute('class', 'offCanvas')
    } else {
      offCanvas.setAttribute('class', 'offCanvas active')
    }
  }

  if(user === false || config_global === false) return (<h1>Cargando...</h1>);

  return (
    <div className={`${(window.location.host.indexOf('localhost') !== -1)?' localhost':''}`}>
    
      <Fragment>
        {
          (user && !offline)?
          <Router>

          <Route exact path="/2021(|/|/logout|/home|/cities|/city|/bases|/convention|/user|/gr)">
            <div className={`container c2021 ${topClass}`}>

            <Header2021 user={user} custom_user={custom_user} config_global={config_global} show_login={true} show_logout={true} />

              <div className="offCanvas">
                <Route exact path="/2021(|/|/login|/home|/cities|/city|/bases|/convention|/user|/gr)">
                  <Nav2021 click_callback={toggleMenu} user={user} custom_user={custom_user} config_global={config_global} />
                </Route>
              </div>

              <div className={`App pageContainer`}>
                <Switch>
                  <Route exact path="/2021(/home|)"    ><Home2021 user={user} custom_user={custom_user} config_global={config_global} setTopClass={setTopClass} /></Route>
                  <Route exact path="/2021/cities"     ><Cities2021 user={user} custom_user={custom_user} config_global={config_global} setTopClass={setTopClass} /></Route>
                  <Route exact path="/2021/city/:id"   ><City2021 user={user} custom_user={custom_user} config_global={config_global} setTopClass={setTopClass} /></Route>
                  <Route exact path="/2021/bases"      ><Bases2021 user={user} custom_user={custom_user} config_global={config_global} setTopClass={setTopClass} /></Route>
                  <Route exact path="/2021/convention" ><Convention2021 user={user} custom_user={custom_user} config_global={config_global} setTopClass={setTopClass} /></Route>
                  <Route exact path="/2021/user"       ><User2021 user={user} custom_user={custom_user} config_global={config_global} setTopClass={setTopClass} /></Route>
                  <Route exact path="/2021/gr"         ><GR2021 user={user} custom_user={custom_user} config_global={config_global} setTopClass={setTopClass} /></Route>
                </Switch>
              </div>

              <footer className="footer">
                <Route exact path="/2021(|/|/login|/home|/cities|/city|/bases|/convention|/user|/gr|/loader)">
                  <Nav2021 config_global={config_global} custom_user={custom_user} />
                  <button className="burger sprite" onClick={(e) => { toggleMenu() }} ></button>
                </Route>
              </footer>

            </div>
          </Route>

          <Route exact path="/2020(|/|/logout|/login|/home|/cities|/city|/bases|/game|/convention|/user|/gr)">
            
            <div className="container c2020">
              <div className="offCanvas">
                <Route exact path="/2020(|/|/login|/home|/cities|/city|/bases|/game|/convention|/user|/gr)">
                  <Nav2020 click_callback={toggleMenu} user={user} custom_user={custom_user} config_global={config_global} />
                </Route>
              </div>

              <div className={`App pageContainer`}>
                <Switch>
                  <Route exact path="/2020(/home|)"    ><Home2020 user={user} custom_user={custom_user} config_global={config_global} /></Route>
                  <Route exact path="/2020/cities"     ><Cities2020 user={user} custom_user={custom_user} config_global={config_global} /></Route>
                  <Route exact path="/2020/city/:id"   ><City2020 user={user} custom_user={custom_user} config_global={config_global} /></Route>
                  <Route exact path="/2020/game"       ><Game2020 user={user} custom_user={custom_user} config_global={config_global} /></Route>
                  <Route exact path="/2020/bases"      ><Bases2020 user={user} custom_user={custom_user} config_global={config_global} /></Route>
                  <Route exact path="/2020/convention" ><Convention2020 user={user} custom_user={custom_user} config_global={config_global} /></Route>
                  <Route exact path="/2020/user"       ><User2020 user={user} custom_user={custom_user} config_global={config_global} /></Route>
                  <Route exact path="/2020/gr"         ><GR2020 user={user} custom_user={custom_user} config_global={config_global} /></Route>
                </Switch>
              </div>

              <footer className="footer">
                <Route exact path="/2020(|/|/login|/home|/cities|/city|/bases|/game|/convention|/user|/gr|/loader)">
                  <Nav2020 config_global={config_global} />
                  <button className="burger sprite" onClick={(e) => { toggleMenu() }} ></button>
                </Route>
              </footer>

              <div className="corner sprite left-top"></div>
              <div className="corner sprite left-bottom"></div>
              <div className="corner sprite rigth-top"></div>
              <div className="corner sprite rigth-bottom"></div>
            </div>
          </Route>

          <Route exact path="/2019(|/|/home|/photos)">
            <div className="container c2019">

              <div className="offCanvas">
                <Route render={(props)=>(
                  <Nav click_callback={toggleMenu} />
                )} />
              </div>

              <div className="App pageContainer">
                <Switch>
                  <Route exact path="/2019(/home|)"><Home2019 user={user} custom_user={custom_user} config_global={config_global} /></Route>
                  <Route exact path="/2019/photos"><Photos2019 user={user} custom_user={custom_user} config_global={config_global} /></Route>
                </Switch>
              </div>

              <footer className="footer">
                <Nav config_global={config_global}/>
                <button className="burger sprite" onClick={(e) => { toggleMenu() }} ></button>
              </footer>

            </div>
          </Route>

          <Route exact path="/2018(|/|/home|/photos)">
            <div className="container c2018">

              <div className="offCanvas">
                <Route render={(props)=>(
                  <Nav click_callback={toggleMenu} config_global={config_global} />
                )} />
              </div>

              <div className="App pageContainer">
                <Switch>
                  <Route exact path="/2018(/home|)"><Home2018 user={user} custom_user={custom_user} config_global={config_global} /></Route>
                  <Route exact path="/2018/photos"><Photos2018 user={user} custom_user={custom_user} config_global={config_global} /></Route>
                </Switch>
              </div>

              <footer className="footer">
                <Nav config_global={config_global} />
                <button className="burger sprite" onClick={(e) => { toggleMenu() }} ></button>
              </footer>

            </div>
          </Route>

          <Route exact path="(/logout)">
            <Logout />
          </Route>
          
          <Route exact path="(/home|)">
            <div className="container convention">

              <Header user={user} custom_user={custom_user} config_global={config_global} />

              <div className="offCanvas">
                <Route render={(props)=>(<Nav user={user} custom_user={custom_user} config_global={config_global} click_callback={toggleMenu} />)} />
              </div>

              <div className="App pageContainer">
                <Switch>
                  <Route exact path="(/home|)"><Home user={user} custom_user={custom_user} config_global={config_global} /></Route>
                </Switch>
              </div>

              {/* <footer className="footer">
                <Nav user={user} custom_user={custom_user} config_global={config_global}/>
                <button className="burger sprite" onClick={(e) => { toggleMenu() }} ></button>
              </footer> */}
            </div>
          </Route>

        </Router>
        :
        <Router>
          <Switch>
            <div className="container convention">
              
              <Route exact path="/(2020/login|/|)">
                <Login2020 user={user} custom_user={custom_user} config_global={config_global} />
              </Route>

              <Route exact path="/2020/register">
                <Register2020 user={user} custom_user={custom_user} config_global={config_global} />
              </Route>

              <Route exact path="(/logout)">
                <Logout />
              </Route>

            </div>
          </Switch>
        </Router>
      }
      </Fragment>

    </div>
  );
}

export default App;
