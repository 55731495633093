import React, { Fragment, useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { auth, db, firestore, analytics } from '../../firebase';

import Header from '../../components/2021/Header';
import Cake from '../../components/2021/Cake';

/**
 * Componente `User`
 */
function User(props) {
  const {user = false}          = props;
  const {custom_user = false}   = props;
  const {config_global} = props;
  const [items, setItems] = useState(false);
  const { setTopClass } = props;

  useEffect(() => {
    setTopClass('user');
  }, []);

  let n = 0;

  useEffect(() => {
    firestore.collection('users_info').where('uid', '==', user.uid).get()
    .then((d) => {
      if(d.docs.length && d.docs[0].exists) {
        setItems(d.docs.map(d=>{
          return d.data();
        }));
  
        console.log('Se cargó la información del usuario.', items);
  
      } else {
        console.error('El usuario no existe.');
      }
    })
  }, [user]);


  if(user === false || config_global === false || items == false) return (<h1>Cargando...</h1>);

  // Nuevos Negocios UF
  // Renovación UF
  // Llevas UF
  
  return(
    <Fragment>
      {
        (items.length)?
        <div className="page">
          <br/>
          <br/>
          <br/>
          <br/>
          <Cake total={items[0].cake.replace('%','').replace(',','.')} />
          <br/>
          <p className="text">Revisa lo cerca que estás de acompañarnos en esta gran travesía.<br/>Comprueba tu progreso para alcanzar tu meta.</p>
          <div className="table_container">
            <table className="rn_Grid">
              <tbody>
                <tr key={'item_' + n++}>
                  <th>GR</th>
                  <td>{(items[0].gr)?items[0].gr:'Sin GR'}</td>
                </tr>
                <tr key={'item_' + n++}>
                  <th>Meta UF</th>
                  <td>{items[0].budget}</td>
                </tr>
                <tr key={'item_' + n++}>
                  <th>Nuevos Negocios UF</th>
                  <td>{items[0].auto}</td>
                </tr>
                <tr key={'item_' + n++}>
                  <th>Renovación UF</th>
                  <td>{items[0].rrvv}</td>
                </tr>
                { (items[0].total)?<tr key={'item_' + n++}>
                  <th>Llevas UF</th>
                  <td>{items[0].total}</td>
                </tr>:null }
                {/* { (items[0].left)?<tr key={'item_' + n++}>
                  <th>Te Faltan UF</th>
                  <td className="">{items[0].left}</td>
                </tr>:null } */}
                {/* { (items[0].left_rrvv)?<tr key={'item_' + n++}>
                  <th>Te Faltan RRVV UF</th>
                  <td className="">{items[0].left_rrvv}</td>
                </tr>:null } */}
              </tbody>
            </table>
          </div>
          
          {/* <div className="contact">
            La información contenida en la tabla podria estar sujeta a cambios. Si tienes dudas, escríbenos a fidelizacion@reale.cl
          </div> */}
        </div>
      :null
    }
    </Fragment>
  )
}

export default withRouter(User);