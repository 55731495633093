import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import MenuItem from './MenuItem';

/**
 * Componente `Menu`
 */
function Menu(props) {
  const { click_callback } = props;
  const { user } = props;
  const { custom_user } = props;
  const { config_global } = props;

  return(
    <Fragment>
      <ul className="menu_list">
        <MenuItem label="Inicio" icon="ico_home" path="/home" click_callback={(click_callback)?click_callback:null} />
        {(custom_user && custom_user.show2018)?<MenuItem label="2018" icon="ico_2018" path="/2018" click_callback={(click_callback)?click_callback:null} />:null}
        {(custom_user && custom_user.show2019)?<MenuItem label="2019" icon="ico_2019" path="/2019" click_callback={(click_callback)?click_callback:null} />:null}
        {(custom_user && custom_user.show2020)?<MenuItem label="2020" icon="ico_2020" path="/2020" click_callback={(click_callback)?click_callback:null} />:null}
        {(custom_user && custom_user.show2021)?<MenuItem label="2021" icon="ico_2021" path="/2021" click_callback={(click_callback)?click_callback:null} />:null}
      </ul>
    </Fragment>
  )
}

export default withRouter(Menu);