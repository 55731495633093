import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';

/**
 * Componente `MenuItem`
 */
function MenuItem({ label, path, icon, click_callback, classStyle='' }) {
  return(
    <li className={`menu_item`}>
        <Fragment>
          <NavLink to={ path } activeClassName="active" onClick={ (e) => {
            if(typeof click_callback !== undefined && click_callback) {
              click_callback();
            }
          }}>
            {(icon)?<div className={`sprite icon ${icon}`}></div>:null}
            <span className={`label` + (classStyle?` ` + classStyle:'')}>{ label }</span>
          </NavLink>
        </Fragment>
      </li>
  )
}

export default withRouter(MenuItem);