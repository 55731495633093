import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';

/**
 * Componente `CityItem`
 */
function CityItem(props) {
  const { id }           = props.item;
  const { title }        = props.item;
  const { image }        = props.item;
  const { date }         = props.item;
  const { description }  = props.item;
  const { url }          = props.item;
  const { link_enabled } = props;

  let arr_description = description.split('<br/>');

  return(
    <Fragment>
      {
        (link_enabled)?
        <a target="_blank" rel="noopener noreferrer" href={url}>
          <div className="city_item" key={id}>
            <div className="image-container">
              <img className="image" src={`/img/cities/${image}`} alt="---"/>
            </div>
            <div className="detail-container">
              <p className="title">{title}</p>
              <div className="description">
                {
                  arr_description.map((element, i) => ((element !== '')?<p key={'p_' + i}>{element}</p>:<Fragment key={'p_' + i}><br/></Fragment>))
                }
              </div>
              <p className="date">{date}</p>
            </div>
          </div>
        </a>
        :
        <div className="city_item" key={id}>
          <div className="image-container">
            <img className="image" src={`/img/cities/${image}`} alt="---"/>
          </div>
          <div className="detail-container">
            <p className="title">{title}</p>
            <p className="description">{description}</p>
            <p className="date">{date}</p>
          </div>
        </div>
      }
    </Fragment>
  )
}

export default withRouter(CityItem);