import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';

/**
 * Componente `MenuItem`
 */
function MenuItem({ label, path, icon, click_callback, target = '_self' }) {
  return(
    <li className="menu_item">
        <Fragment>
          <NavLink to={ path } target={target} activeClassName="active" onClick={ (e) => {
            if(typeof click_callback !== undefined && click_callback) {
              click_callback();
            }
          }}>
            <div className={`sprite_home icon ${icon}`}></div>
            <span className="label">{ label }</span>
          </NavLink>
        </Fragment>
      </li>
  )
}

export default withRouter(MenuItem);