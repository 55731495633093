import React, { Fragment, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { auth, db, firestore, analytics, firebase } from '../firebase';

/**
 * Componente `Trivia`
 */
function Trivia(props) {
  const { click_callback } = props;
  const { user } = props;
  const { custom_user } = props;
  const { config_global } = props;

  const [currentQuestion, setCurrentQuestion] = useState(0);
	const [showResult, setShowResult] = useState(null);
	const [showEnd, setShowEnd] = useState(false);
  const [score, setScore] = useState(0);
  const [isCorrect, setIsCorrect] = useState(null);
  const [questions, setQuestions] = useState(null);
  const [notificated, setNotificated] = useState(false);
  const [step, setStep] = useState(0);
  
  const handleAnswerOptionClick = (opt) => {
		if (questions[currentQuestion].a === opt.id) {
			setScore(score + 1);
      setIsCorrect(true);
		} else {
      setIsCorrect(false);
    }

    const nextQuestion = currentQuestion + 1;

    updateTrivia(opt, nextQuestion, score, (questions[currentQuestion].a === opt.id), !(nextQuestion < questions.length));

    setShowResult(true);
  };

  const showNextQuestion = () => {
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < questions.length) {
			setCurrentQuestion(nextQuestion);
		} else {
      setShowEnd(true);
    }

    setShowResult(false);
  }

  const updateTrivia = async (opt, position, score, isCorrect, isEnd) => {
    try {
      const obj = {
        lastStep: position,
        isEnd: isEnd,
        notificated: (isEnd)?true:false,
        score: firebase.firestore.FieldValue.increment((isCorrect)?1:0),
        responses: firebase.firestore.FieldValue.arrayUnion({
          selected: opt.id,
          correct: isCorrect,
          responseTime: new Date()
        })
      }

      if(isEnd && !notificated && score > 4) {
        addNotification();
      }

      await firestore.collection('trivia_responses_' + step).doc(user.uid).set(obj, { merge: true });
    } catch(e) {
      console.log('Error: ', e);
    }
  }

  const addNotification = async () => {
    try {
      const obj = {
        recipients: [user.email],
        body: '',
        send: true,
        sended: false
      }

      await firestore.collection('notifications').add(obj);
    } catch(e) {
      console.log('Error: ', e);
    }
  }

  const archiveTrivia = async () => {
    let trivia_response = await (await firestore.collection('trivia_responses_' + step).doc(user.uid).get()).data();
    trivia_response.archiveTime = new Date();

    try {
      const obj = {
        lastStep: 0,
        isEnd: false,
        score: 0,
        responses: null,
        archived: firebase.firestore.FieldValue.arrayUnion(trivia_response),
      }

      await firestore.collection('trivia_responses_' + step).doc(user.uid).set(obj, { merge: true });

      setScore(0);
      setCurrentQuestion(0);
      setShowResult(false);
      setShowEnd(false);
    } catch(e) {
      console.log('Error: ', e);
    }
  }
  
  const getQuestions = async () => {
    const trivia_response = await firestore.collection('trivia').where('enabled', '==', true).get();
    const arr_questions = trivia_response.docs.map(d => {
      return d.data();
    });

    setStep(arr_questions[0].step);

    await getTriviaResponses(arr_questions[0].step);

    setQuestions(arr_questions);
  };
  
  const getTriviaResponses = async (_step) => {
    _step = _step || step;

    const trivia_response = await firestore.collection('trivia_responses_' + _step).doc(user.uid).get();
    
    if(trivia_response.exists) {
      setCurrentQuestion(trivia_response.data().lastStep);
      setScore(trivia_response.data().score);
      setShowEnd(trivia_response.data().isEnd);
      setNotificated(trivia_response.data().notificated);
    }
  };

  useEffect(() => {
    getQuestions();
  }, []);

  if(!questions || !user || !custom_user || !config_global) return <pre>Cargando Preguntas... </pre>

  return(
    <Fragment>
      <div className="trivia">
      {
        (showEnd)?
          <div className='results'>
            <p><strong>¡Gracias por responder la Trivia!</strong></p>
            {
              (score >  2)?
              <Fragment>
                <h3>¡FELICIDADES GANASTE!</h3>
                <p>Te contactaremos para coordinar la entrega de tu premio 😉</p>
              </Fragment>
              :
              <Fragment>
                <h3>INTÉNTALO DE NUEVO</h3>
                <p>Tienes una nueva oportunidad ¡Ahora sí que ganas 👍!</p>
                <div className="btn" onClick={archiveTrivia}>Reintentar</div>
              </Fragment>
            }
          </div>
        : (!showEnd)?
          <Fragment>
            <div className="points">Llevas <strong>{score}</strong> puntos</div>
            <h2 className="title">{currentQuestion + 1} . {questions[currentQuestion].q}</h2>
            <div className="answers">
            {
              questions[currentQuestion].opts.map((option, i) => (
                (option && option.value)?
                <span className={`answer${showResult?' disabled':''}`} key={`answer_` + i} onClick={(!showResult)?() => handleAnswerOptionClick(option):null}>
                  {i+1}. {option.value}
                </span>
                :null
              ))
            }
            </div>
            <div className="result">
            {(showResult && isCorrect)?
            <div className="message success">
              <p>¡Correcto!</p>
              <div className="btn" onClick={showNextQuestion}>Siguiente</div>
            </div>
            :null}
            {(showResult && !isCorrect)?
            <div className="message error">
              <p>Incorrecto</p>
              <div className="btn" onClick={showNextQuestion}>Siguiente</div>
            </div>
            :null
            }
            </div>
            <div className="paginator">Pregunta {currentQuestion + 1}/{questions.length}</div>
          </Fragment>
        : null
      }
      </div>
    </Fragment>
  )
}

export default withRouter(Trivia);