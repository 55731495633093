import React, { Fragment, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { auth, db, firestore, analytics } from '../../firebase';

/**
 * Componente `Home`
 */
function Home(props) {
  const { user } = props;
  const { custom_user } = props;
  const { config_global } = props;
  const { setTopClass } = props;

  useEffect(() => {
    setTopClass('home')
  }, []);


  if(!auth.getUid()) return 'No estás autenticado.';

  return(
    <Fragment>
      <div className="page home">
        <div className="isotipo2021">
        {/* <span className="logo_squadra"></span> */}
        </div>
      </div>
    </Fragment>
  )
}

export default withRouter(Home);
