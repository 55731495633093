import React, { useEffect } from 'react';
import { withRouter } from 'react-router';

/**
 * Componente `InputField`
 */
function InputField(props) {
  const { id }              = props;
  const { name }            = props;
  let   { type }           = props;
  let   { value }           = props;
  // let   default_value       = '';
  const { label }           = props;
  let   { disabled }        = props;
  const { required }        = props;
  const { readonly }        = props;
  // const { autofocus }       = props;
  // const { placeholder }     = props;
  // const { minlength }       = props;
  // const { maxlength }       = props;
  const { change_callback } = props;
  // const { error_callback }  = props;

  disabled = (disabled === 'true')?true:false;

  let className = '';
  if(required) className += ' required';
  if(disabled) className += ' disabled';
  if(readonly) className += ' readonly';
  
  // let [_value, _setValue] = useState('yyy');

  /**
   * Maneja el valor modificado
   * 
   * @param {string} value Valor del campo
   */
  const changeValue = (_value) => {
    // _setValue(value);

    if(change_callback) change_callback.map(fn => {
      return fn(_value);
    });
  }

  useEffect(() => {
    if(change_callback) change_callback.map(fn => {
      return fn(value);
    })
  }, [])

  return(
    
    <div className={`InputField field input ${className}`}>

      <div className="label">
        <label htmlFor="">{label}{ required?<span className="required">*</span>:null }</label>
      </div>
      <div className="input">
        <input
          id={id}
          name={name}
          { ...(type && { type:type }) }
          { ...(disabled && { disabled:disabled }) }
          { ...(id && { id:id }) }
          { ...(name && { name:name }) }
          value={value || ''}
          className="bg_deg"
          onChange={e => changeValue(e.target.value)}
        />
      </div>
    </div>
  )
}

export default withRouter(InputField);

