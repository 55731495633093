import React, { Fragment, useEffect } from 'react';
import { withRouter } from 'react-router';
import { auth, db, firestore, analytics } from '../../firebase';

import Header from '../../components/2020/Header';

function Convention(props) {
  const { user } = props;
  const { custom_user } = props;
  const { config_global } = props;
  const { setTopClass } = props;

  useEffect(() => {
    setTopClass('convention2021')
  }, []);

  return(
    <Fragment>
      <div className="page dark-10">
        {/* <Header user={user} custom_user={custom_user} config_global={config_global} /> */}
        <div className="img-title"></div>
        <div className="img-subtitle"></div>
        <p className="text">Te invitamos a un viaje mágico a una tierra cautivante. A un lugar único en el mundo: Grecia es sol, mar,<br/>paisajes inolvidables y zonas arqueológicas que aún conservan memorias de un pasado extraordinario.</p>
        <article>
          <iframe className="video" title="presentation" width="2560" height="777" src={(config_global?.c2021?.video)} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          <div className="center-content">
          {/* <span className="logo_reale"></span> */}
          {/* <span className="logo_squadra"></span> */}
          </div>
        </article>
      </div>
    </Fragment>
  )
}

export default withRouter(Convention);