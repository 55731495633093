import React, { Fragment, useState } from 'react';
import { withRouter } from 'react-router';
import { auth, db, firestore, analytics } from '../../firebase';
import Header from '../../components/2020/Header';

/**
 * Componente `GR`
 */
function GR(props) {
  const { user } = props;
  const { custom_user } = props;
  const { config_global } = props;

  const [loaded, setLoaded] = useState(false);
  const [items, setItems] = useState([]);

  let n = 0;

  if(!loaded) {
    firestore.collection('users_info').where('uid_gr', '==', auth.getUid()).get()
    .then((d) => {
      if(d.docs.length && d.docs[0].exists) {
        setItems(d.docs.map(d=>{
          return d.data();
        }));

        setLoaded(true);

        console.log('Se cargó la información de corredores.', items);

      } else {
        console.error('No hay corredores asociados.');
      }
    })
  }
  
  return(
    <Fragment>
      {
        (items.length)?
        <div className="page">
          <Header user={user} custom_user={custom_user} config_global={config_global} />
          <h1>Información de Corredores</h1>
          <p className="text">¡Consulta el desempeño de tus corredores!</p>
          <div className="table_container full">
            <table className="rn_Grid ">
              <tbody>
                <tr>
                  <th>Nombre</th>
                  <th>Meta UF</th>
                  <th>Llevas UF</th>
                  <th>% RR.VV</th>
                  <th>% Auto</th>
                </tr>
                {
                  items.map((d, i) => {
                    return <tr key={`row_${i}`}>
                      <td>{d.name}</td>
                      <td>{d.budget}</td>
                      <td>{d.total}</td>
                      <td>{d.rrvv}</td>
                      <td>{d.auto}</td>
                    </tr>
                  })
                }
               
              </tbody>
            </table>
          </div>
          
          <div className="contact">
          La información contenida en la tabla podria estar sujeta a cambios. Si tienes dudas, escríbenos a fidelizacion@reale.cl
          </div>
        </div>
      :null
    }
    </Fragment>
  )
}

export default withRouter(GR);