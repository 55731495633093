import React, { Fragment, useEffect } from 'react';
import { withRouter } from 'react-router';
import { auth, db, firestore, analytics } from '../../firebase';
import * as d3 from 'd3';
// import { values } from 'd3-collection';
// import Menu from './Menu';
import Utils from '../../Utils';

/**
 * Componente `Cake`
 */
function Cake(props) {
  const { total = 0 } = props;

  let svg_height,
      svg_width,
      margins,
      radius,
      colors,
      text_colors,
      svg;

    
  const label_prefix             = '',
        label_sufix              = '',
        label_format_number_type = '',
        label_format_time_type   = '',
        legend_show              = false,
        labels_show              = false,
        width                    = 150,
        height                   = 150;

  useEffect(() => {
    const data = [{"key":"","value":total},{"key":"","value":(100-total)}];
    drawChart(null, data);
  }, []);

  const drawChart = (baseSelector = null, data = []) => {
    // DEV: Expone la librería
    window.d3 = d3;

    /////////////////////////////////////////////////////////////////////////////////

    // General
    svg_width  = width;
    svg_height = height;

    // Margenes
    margins = { top: 10, left: 10, rigth: 10, bottom: 10, x_axis: 10, y_axis: 10 };

    // Configuración
    radius = Math.min(svg_width - (margins.left + margins.rigth), svg_height - (margins.top + margins.bottom)) / 2

    // Colores
    let colors      = ['#004b9b', '#ffffff', '#5c9b00', '#9b3d00', '#f0b400', '#004b9b', '#5c9b00', '#9b3d00'];
    let text_colors = ['#00152b', '#ffffff', '#182900', '#1d0b00', '#3c2d00', '#00152b', '#182900', '#1d0b00'];

    // SVG
    svg = d3.select(((baseSelector)? baseSelector:'body') + ' svg')
      .attr('width', svg_width)
      .attr('height', svg_height)
      .append('g')
      .attr('transform', 'translate(' + (legend_show?(radius + margins.left):(svg_width / 2)) + ', ' + svg_height / 2 + ')')
      
    svg
      .append('text')
      .text(total.replace('.', ',') + '%')
      .style("text-anchor", "middle")
      .attr('font-size', 25)
      .attr('transform', 'translate(' + 0 + ' , ' + 10 + ')')
      .attr('fill', colors[0])

    const arc_over = function() {
      d3.select(this)
        .select('path')
        .transition()
        .duration(100)
        .attr('d', arc2)
  
      d3.select(this)
        .raise()
      
      d3.select(this)
        .transition()
        .duration(200)
        .attr('stroke-opacity', '1')
        .attr('stroke-width', '2')
  
    }
  
    const arc_out = function() {
      d3.select(this)
        .select('path')
        .transition()
        .duration(100)
        .attr('d', arc)
  
      d3.select(this)
        .transition()
        .duration(200)
        .attr('stroke-opacity', '0')
        .attr('stroke-width', '0')
    }

    const pie = d3.pie()
      .value((d, i) => d)
      .sort(null)

    // pie

    // Arcos
    const arc = d3.arc()
      .innerRadius(45)
      .outerRadius(radius)

    const arc2 = d3.arc()
      .innerRadius(45)
      .outerRadius(radius + 5);

    const slices = svg
      .selectAll('g.slice')
      .data(pie(data.map((d, i) => { return d.value })))
      .enter()
      .append('g')
      .attr('class', 'slice')
      // .attr('stroke', '#0000005c')
      // .attr('stroke-width', '0')
      // .attr('stroke-opacity', '0')
      // .attr('paint-order', 'stroke')
      .on('mouseover', arc_over)
      .on('mouseout', arc_out)

    console.log('\n', baseSelector);

    slices.append('path')
      .attr('fill', (d, i) => { console.log(d.value, i, colors[i]); return colors[i] })
      .attr('d', arc)
      // .each(function(d) { _current = d; })

    // Etiquetas
    if(labels_show) {
      slices
        .append('text')
        .text((d, i) => {
          if(label_format_number_type) {
            return (label_prefix?label_prefix:'') + Utils.numberFormat(d.value, label_format_number_type) + ' ' + (label_sufix?label_sufix:'');
          } else {
            return (label_prefix?label_prefix:'') + d.value + (label_sufix?label_sufix:'');
          }
        })
        .attr('transform', (d) => { return 'translate(' + arc.centroid(d) + ')' })
        .attr('text-anchor', 'middle')
        .attr('class', 'label')
        .attr('font-size', 12)
        .attr('fill', (d, i) => { return text_colors[i] } )
        .attr('font-weight', 'bold')
    }

    // let colors = colors;
    
    // Leyendas
    if(legend_show) {
      const legends = svg
        .append('g')
        .attr('class', 'legends')
        .attr('transform', 'translate(0 , ' + ((data.length * 25 / 2) * -1) + ')')
  
      const legend = legends
        .selectAll('g.legend')
        .data(pie(data))
        .enter()
        .append('g')
        .attr('class', 'legend')
        .attr('transform', (d, i) => {
          return 'translate(' + (radius + margins.left) + ', ' + i*30 + ')';
        })
  
        legend
        .append('rect')
        .attr('width', 20)
        .attr('height', 20)
        .attr('rx', 2)
        .attr('fill', (d, i) => { return colors[i] })
        .attr('stroke', '#000')
        .attr('stroke-width', '1')
        .attr('stroke-opacity', '1')
  
        legend
          .append('text')
          .text((d, i) => {
            return d.data.key
          })
          .attr('transform', (d, i) => {
            return 'translate(' + 30 + ', ' + 15 + ')';
          })
  
        .attr('stroke', '#000')
        .attr('stroke-width', '0')
        .attr('stroke-opacity', '0')
    }
    
    /////////////////////////////////////////////////////////////////////////////////

    window.groupChart = svg.select('#group-chart');
  }

  return(
    <Fragment>
      <svg className="group-chart"></svg>
    </Fragment>
  )
}

export default withRouter(Cake);
