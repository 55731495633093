import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import { auth, db, firestore, analytics } from '../../firebase';

import Header from '../../components/2020/Header';

function Convention(props) {
  const { user } = props;
  const { custom_user } = props;
  const { config_global } = props;

  return(
    <Fragment>
      <div className="page dark-10">
        <Header user={user} custom_user={custom_user} config_global={config_global} />
        <article>
          <h1>Convención 2020</h1>
          <p className="text">Este año desde 30/11/2020 al 12/12/2020 (fecha sujeta a evaluación) te invitamos a descubrir la historia, arquitectura y cultura de la realeza europea. Queremos compartir contigo toda la tradición de las Capitales Imperiales.</p>
          <p className="text">Para conocer en detalle las ciudades que visitaremos te invitamos a ver el videos y a hacer clic en la pestaña "ciudades".</p>
          <iframe className="video" title="presentation" width="2560" height="777" src="https://www.youtube.com/embed/_LD0TmktHg0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          <br/>
          <iframe className="video" title="presentation" width="2560" height="777" src="https://www.youtube.com/embed/W7V3RDBU0sE" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </article>
      </div>
    </Fragment>
  )
}

export default withRouter(Convention);