import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { auth, db, firestore, analytics } from '../../firebase';
import Header from '../../components/2020/Header';

function Bases(props) {
  const { user } = props;
  const { custom_user } = props;
  const { config_global } = props;
  const { setTopClass } = props;

  useEffect(() => {
    setTopClass('bases')
  }, []);

  const [loaded, setLoaded] = useState(false);

  /**
   *
   */
  const savePDF = () => {
    if(custom_user.code) window.open(`https://squadra.reale.cl/bases/${custom_user.code}.pdf`);

    return true;
  }

  return(
    <div className="page dark-10">
      {/* <h1>Bases Legales</h1> */}
      <br/>
      <br/>
      <br/>
      <br/>
      <p>Si quieres conocer las bases legales de esta increíble travesía, descarga PDF en el siguiente enlace.</p>
      <br/>
      <p>
        <button className="btn" onClick={(e) => {
          // init();
          savePDF();
        } }>
          <div className="sprite icon ico_download"></div>
          <div className="label">Descargar</div>
        </button>
      </p>
    </div>
  )
}

export default withRouter(Bases);