import React, { Fragment, useState } from 'react';
import { withRouter } from 'react-router';
import InputField from '../../../components/InputField'
import { auth, db, firestore, analytics } from '../../../firebase';
import { Link } from 'react-router-dom';
import {showAuthError, showMessage, isRUT, formatRUT} from '../../../Utils';

const Register = (props) => {
  
  const { user } = props;
  const { custom_user } = props;
  const { config_global } = props;

  // const [uid, setUid]           = useState('');
  const [name, setName]         = useState('Álvaro Lagos');
  const [email, setEmail]       = useState('alagos@integer.cl');
  const [rut, setRut]           = useState('11111111-1');
  const [password, setPassword] = useState('123456');
  const [total, setTotal]       = useState(1000);
  const [budget, setBudget]     = useState(1000);
  
  const formSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const newUser = await auth.createUserWithEmailAndPassword(
        email,
        password
      );

      try {
        const user = {
          uid: newUser.user.uid,
          name,
          email,
          rut,
          total,
          budget,
          createdTime: new Date()
        }
        
        await firestore.collection('users_info').add(user)
  
        custom_user(user);

      } catch(e) {
        console.log('Error al crear usuario: ', e);
      }
  
    } catch(e) {
      if(e.code === 'auth/email-already-in-use') {
        alert('Error, el correo electrónico ya está registrado.');
      } else {
        alert(e.message);
      }
    }
  }

  return (
    <Fragment>
      <div className="page">
      <h1>Registrar Usuario</h1>
      <form onSubmit={ formSubmit } className="register vcenter">
        <InputField label="Nombre" type="text" name="name" value={ name } change_callback={[setName]} />
        <InputField label="Correo Electrónico" type="text" name="email" value={ email } change_callback={[setEmail]} />
        <InputField label="RUT" type="text" name="rut" value={ rut } change_callback={[setRut]} />
        <InputField label="Contraseña" type="text" name="password" value={ password } change_callback={[setPassword]} />
        <InputField label="Total" type="number" name="total" value={ total } change_callback={[setTotal]} />
        <InputField label="Presupuesto" type="number" name="budget" value={ budget } change_callback={[setBudget]} />
        <input type="submit" value="Registrar" className="btn" />
        <Link className="btn btn-second" to="/">
          Volver
        </Link>
      </form>
      </div>
    </Fragment>
  );
}

export default withRouter(Register);