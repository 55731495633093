import React, { Fragment, useEffect } from 'react';
import { withRouter } from 'react-router';
import { auth, db, firestore, analytics } from '../../firebase';

import Header from '../../components/2020/Header';
import CityItem from '../../components/2020/CityItem';

/**
 * Componente `City`
 */
function City(props) {
  const { user } = props;
  const { custom_user } = props;
  const { config_global } = props;
  const { cityItem }    = props;
  const { setTopClass } = props;

  useEffect(() => {
    setTopClass('city')
  }, []);

  if(!cityItem) return <pre>Cargando...</pre>
  
  return(
    <Fragment>
      <div className="page dark-10">
        <Header user={user} custom_user={custom_user} config_global={config_global} />
        <h1>Ciudad</h1>
        <div className="cities_items">
          <CityItem className="city_item" key={CityItem.id} item={CityItem} link_enabled={false} />
        </div>
      </div>
  </Fragment>
  )
}

export default withRouter(City);
