import React, { Fragment, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { auth, db, firestore, analytics } from '../../firebase';

import CityItem from '../../components/2021/CityItem';


/**
 * Componente `Cities`
 */
function Cities(props) {
  // const { cities }  = props;
  const { user } = props;
  const { custom_user } = props;
  const { config_global } = props;
  const { setTopClass } = props;
  
  const [cities, setCities] = useState(null);
  
  useEffect(() => {
    setTopClass('cities')
    loadCities();
  }, []);

  const loadCities = async () => {
    const custom_user_response = await firestore.collection('cities').where('year', '==', 2021).get();
    const arr_cities = custom_user_response.docs.map(d => {
      return d.data();
    });

    setCities(arr_cities);
  }

  if(!cities || !user || !custom_user || !config_global) return <pre>Cargando... </pre>
  
  return(
    <Fragment>
      <div className="page dark-10">
        {/* <h1>Ciudades y Actividades</h1> */}
        <div className="cities_items">
          {
            cities.map(
              (item, i) => (
                <CityItem className="city_item" key={i} item={item} link_enabled={true} />
              )
            )
          }
        </div>
      </div>
  </Fragment>
  )
}

export default withRouter(Cities);