import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import { auth, db, firestore, analytics } from '../../firebase';
import ImageGallery from 'react-image-gallery';

/**
 * Componente `Photos`
 */
function Photos(props) {
  const images = [{
    original: '/photos/2018/01_image.jpeg',
    thumbnail: '/photos/2018/01_thumbs.jpeg',
  },
  {
    original: '/photos/2018/02_image.jpeg',
    thumbnail: '/photos/2018/02_thumbs.jpeg',
  },
  {
    original: '/photos/2018/03_image.jpeg',
    thumbnail: '/photos/2018/03_thumbs.jpeg',
  },
  {
    original: '/photos/2018/04_image.jpeg',
    thumbnail: '/photos/2018/04_thumbs.jpeg',
  },
  {
    original: '/photos/2018/05_image.jpeg',
    thumbnail: '/photos/2018/05_thumbs.jpeg',
  },
  {
    original: '/photos/2018/06_image.jpeg',
    thumbnail: '/photos/2018/06_thumbs.jpeg',
  },
  {
    original: '/photos/2018/07_image.jpeg',
    thumbnail: '/photos/2018/07_thumbs.jpeg',
  },
  {
    original: '/photos/2018/08_image.jpeg',
    thumbnail: '/photos/2018/08_thumbs.jpeg',
  },
  {
    original: '/photos/2018/09_image.jpeg',
    thumbnail: '/photos/2018/09_thumbs.jpeg',
  },
  {
    original: '/photos/2018/10_image.jpeg',
    thumbnail: '/photos/2018/10_thumbs.jpeg',
  },
  {
    original: '/photos/2018/11_image.jpeg',
    thumbnail: '/photos/2018/11_thumbs.jpeg',
  },
  {
    original: '/photos/2018/12_image.jpeg',
    thumbnail: '/photos/2018/12_thumbs.jpeg',
  },
  {
    original: '/photos/2018/13_image.jpeg',
    thumbnail: '/photos/2018/13_thumbs.jpeg',
  },
  {
    original: '/photos/2018/14_image.jpeg',
    thumbnail: '/photos/2018/14_thumbs.jpeg',
  },
  {
    original: '/photos/2018/15_image.jpeg',
    thumbnail: '/photos/2018/15_thumbs.jpeg',
  },
  {
    original: '/photos/2018/16_image.jpeg',
    thumbnail: '/photos/2018/16_thumbs.jpeg',
  },
  {
    original: '/photos/2018/17_image.jpeg',
    thumbnail: '/photos/2018/17_thumbs.jpeg',
  }
];

  return(
    <Fragment>
      <div className="page">
        <ImageGallery items={images} />
      </div>
    </Fragment>
  )
}

export default withRouter(Photos);
