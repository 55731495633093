import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import Menu from './Menu';

/**
 * Componente `Nav`
 */
function Nav(props) {
  const { click_callback } = props;
  const { user } = props;
  const { custom_user } = props;
  const { config_global } = props;

  return(
    <Fragment>
      {(typeof click_callback !== 'undefined')?<Menu click_callback={click_callback} user={user} custom_user={custom_user} config_global={config_global} />:<Menu user={user} custom_user={custom_user} config_global={config_global} />}
    </Fragment>
  )
}

export default withRouter(Nav);